// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin: 0;
    color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.css"],"names":[],"mappings":"AAAA;IACI,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;IACtB,UAAU;IACV,SAAS;IACT,uBAAuB;AAC3B","sourcesContent":["body {\n    background-image: url('../../../public/banner.jpg');\n    background-repeat: no-repeat;\n    background-size: cover;\n    padding: 0;\n    margin: 0;\n    color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
