import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage/index'; // пример дочернего компонента
// import AboutPage from './pages/AboutPage'; // еще один пример
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          {/* <Route path="about" element={<AboutPage />} />  */}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
